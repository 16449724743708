import React from "react";

import { string, bool } from "prop-types";
import styled from "styled-components";

// import Github from "../../svg/github.svg";
// import Freshdesk from "../../svg/freshdesk.svg";
// import Status from "../../svg/status.svg";

// import Sms from "../../svg/SMS.svg";
// import Mail2Sms from "../../svg/mail2SMS.svg";

const Tool = ({ width, title, icon, desc, link, html, intern }) => {

  return (
    <Wrapper
      href={link}
      target={!intern ? "_blank" : ""}
      rel={!intern ? "noopener noreferrer" : ""}
    >
      <span>{title}</span>
      {width > 555 && (
        <>
          {html ? (
            <p dangerouslySetInnerHTML={{ __html: desc }} />
          ) : (
            <p>{desc}</p>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.a`
  text-decoration: none;
  width: 320px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: ease 0.275s;
  background-color: ${({ theme }) => theme.second};
  border-radius: 10px;
  @media ${({ theme }) => theme.breakpoints.small} {
  }
  & > span {
    font-weight: 500;
    font-size: 16px;
    color: #52b474;
  }
  & > p {
    font-size: 12px;
    margin-top: 10px;
    line-height: 24px;
    color: ${({ theme }) => theme.text};
    @media ${({ theme }) => theme.breakpoints.small} {

      font-size: 16px;
    }
  }
  & > p > sup {
    vertical-align: super;
  }
  &:hover {
    transform translateY(-.1em);
  }
  @media (max-width: 1040px) {
    margin: 10px;
  }
  @media (max-width: 1435px) and (max-height: 764px) {
    transform: scale(0.9);
  }
`;


Tool.propTypes = {
  label: string,
  html: bool
};

Tool.defaultProps = {
  label: "",
  html: false
};

export default Tool;
