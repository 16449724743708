import React, { useContext } from "react";
import styled from "styled-components";
import { Title } from "../components/PageElements";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { getName, getVersion } from "../helpers/utils";
import Tool from "../components/Tool";
import ApiCard from "../components/ApiCard";
import { Sup } from "../components/PageElements";
import { Context } from "../components/Layout";
import MovingWave from "../components/PageElements/MovingWave";

const getPath = (node) => node.paths[0];

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allApi {
        nodes {
          name
          info {
            description
            title
            version
          }
          tags {
            name
          }
          paths {
            path
            additionalData {
              summary
              version
            }
          }
        }
      }
    }
  `);

  const { allApi } = data || {};
  const { nodes } = allApi || {};

  const { width } = useContext(Context) || {};

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>smsmode© | API Reference</title>
        <meta
          name="description"
          content="Get all the information you need to integrate smsmode© services. Full documentation, source codes, tests, and functions to help you add SMS to your app."
          data-react-helmet="true"
        />
      </Helmet>
      <Container>
        <TitleWrapper>
          <h2 className="title2">DOCUMENTATION</h2>
          <Title className="title1">
            Explore our guides and examples to integrate smsmode<Sup>©</Sup>{" "}
            services
          </Title>
        </TitleWrapper>
        <ApisContainer>
          <Column>
            {nodes
              ?.filter?.((node, idx) => node.name === "COMMONS REST API") // tmp
              .map?.((node, idx) => (
                <ApiCard
                  key={getName(node.name)}
                  desc={node.info?.description}
                  link={
                    `/${getName(node.name)}/${getVersion(
                      getPath(node)
                    )}/home` || "/"
                  }
                  info={node?.info}
                />
              ))}
          </Column>
          {width > 1030 && <Divider />}
          <Column>
            {nodes
              ?.filter?.((node, idx) => node.name === "SMS REST API") // tmp
              .map?.((node, idx) => (
                <ApiCard
                  key={getName(node.name)}
                  desc={node.info?.description}
                  link={
                    `/${getName(node.name)}/${getVersion(
                      getPath(node)
                    )}/home` || "/"
                  }
                  info={node?.info}
                />
              ))}
          </Column>
        </ApisContainer>

        <ApisContainer style={{ alignItems: "center", marginTop: "30px" }}>
          <ApiCard
            desc="Visit our technical documentation."
            link="/http-api/reference"
            info={"HTTP API"}
            deprecated
          />
          {width > 1030 && <Divider style={{ marginTop: "-30px" }} />}
          <ApiCard
            desc="The Mail2SMS is a service that allows you to send SMS directly from your inbox."
            link="/mail2sms"
            info={"MAIL2SMS API"}
          />
        </ApisContainer>
        <Tools>
          <div>
            <Tool
              width={width}
              title="StatusPage"
              icon="status"
              desc="Visit our StatusPage to follow the status end-user features and services."
              link="https://statuspage.smsmode.com/"
            />
            <Tool
              width={width}
              title="24/7 Customer Support"
              icon="freshdesk"
              desc="If you need specific help, you may use our support ticketing system."
              link="https://ticket.smsmode.com/tickets-view"
            />
            <Tool
              width={width}
              title="Github PHP Librairy"
              icon="github"
              desc="(version 2.8.1 certified by smsmode<sup>©</sup> - later versions are not verified)."
              link="https://github.com/webeweb/smsmode-library"
              html
            />
          </div>
        </Tools>
      </Container>
      <MovingWave className="WaveWrapper" color="#52b4743d" invert />
    </Wrapper>
  );
};

const Tools = styled.div`
  padding: 0 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > h2 {
    font-size: 21px;
    font-weight: bold;
    margin-top: 70px;
  }
  & > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;
  }

  @media (max-width: 1040px) {
    padding: 0 10px;

    & > h2 {
      margin: 30px 0;
      font-size: 16px;
      text-align: center;
    }

    & > div:last-child,
    & > div {
      justify-content: center;
      margin: 0;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1030px) {
    align-items: center;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 300px;
  background-color: ${({ theme }) => theme.borderColor};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1232px;
  width: 100%;
  z-index: 100;
`;

const ApisContainer = styled.div`
  display: flex;
  flex: row;
  justify-content: center;
  @media ${({ theme }) => theme.breakpoints.small} {
    justify-content: space-between;
  }
  width: 100%;
  margin-top: 20px;
  padding: 0 20px;
  @media (max-width: 1030px) {
    padding: 0 10px;
    flex-direction: column;
    margin-top: 0px;
    & > div:last-child {
      margin-top: 30px;
    }
    #margin {
      margin-top: 40px !important;
    }
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & > h1 {
    max-width: unset;
  }
  & > .title2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  @media (max-width: 1030px) {
    padding: 10px;
  }
  padding: 40px 20px 10px 20px;
`;

export default IndexPage;
