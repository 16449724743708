import React, { useContext, useState } from "react";
import { Link } from "gatsby";

import { string } from "prop-types";
import styled from "styled-components";

import { Context } from "../Layout/index";
import { getName, getApiCardImg } from "../../helpers/utils";

const ApiCard = ({ desc, link, info, deprecated }) => {
  const { currentTheme } = useContext(Context) || {};
  const [hover, setHover] = useState();

  return (
    <Wrapper data-testid="ApiCard-Wrapper">
      <ImgLink
        data-testid="ApiCard-ImgLink"
        to={link}
        hover={hover ? `0px 10px 20px rgba(0, 0, 0, 0.1)` : ""}
      >
        {getApiCardImg(currentTheme, getName(info?.title || info))}
      </ImgLink>
      <Container
        role="button"
        tabIndex={0}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Title deprecated>
          <h1>{(info?.title && info?.title.toUpperCase()) || info}</h1>
          {deprecated && <Deprecated>DEPRECATED</Deprecated>}
        </Title>
        <p
          style={{ lineHeight: "24px" }}
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      </Container>
    </Wrapper>
  );
};

const Title = styled.div`
  display: ${({ deprecated }) => deprecated && `flex`};
  & > h1 {
    margin-bottom: 8px;
  }
`;

const Deprecated = styled.div`
  padding: 4px;
  border-radius: 5px;
  background-color: ${({ theme }) => `${theme.second}`};
  color: #fd6565;
  margin-left: 10px;
  height: fit-content;
  margin-top: 32px;
`;

const Container = styled.div`
  & > div > h1 {
    font-size: 21px;
    padding-top: 32px;
    font-weight: bold;
  }
  & > div > p {
    min-height: 50px;
    line-height: 24px;
    font-size: 16px;
    margin: 8px 0 12px;
  }
  @media (max-width: 1030px) {
    & > div > h1 {
      font-size: 16px;
    }
    & > div > p {
      font-size: 14px;
    }
  }
`;

const ImgLink = styled((props) => <Link {...props} />)`
  border-radius: 5px;
  transition: 0.275s ease;
  box-shadow: ${({ hover }) => hover};
  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  }
  > * {
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
  }
  @media (max-width: 1130px) {
    transform: scale(0.9);
  }
`;

const Wrapper = styled.div`
  @media (max-width: 1030px) {
    width: 100%;
  }

  @media (max-width: 1435px) {
    transform: scale(0.8);
  }
  width: 475px;
  max-width: 475px;
  display: flex;
  flex-direction: column;
`;

ApiCard.propTypes = {
  label: string
};

ApiCard.defaultProps = {
  label: ""
};

export default ApiCard;
